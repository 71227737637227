import React from "react"

import wgpImage from "../../images/services1.jpg"
import g2EVegas from "../../images/services2.jpg"
import casinoRiskForum from "../../images/serv3.jpg"
import { container, copy, image, imagesContainer } from "./styles.module.less"

const Services = () => {
  return (
    <div className={container}>
      <div className={copy}>
        <h1>Services</h1>
        <h3>Game Protection Training</h3>

        <p>We come to you! On site, job-specific programs are available for:</p>
        <ul>
          <li>
            Full time Casino Supervisors and Managers - 4, 6, or 8-hour
            sessions*
          </li>
          <li>Dual-Rate Supervisor/Dealers - 4-hour session</li>
          <li>Dealers - 2-hour session</li>
          <li>Surveillance and Regulatory Agents - 8-hour session</li>
        </ul>
        <aside>
          *Session times can be adjusted to fit most budgetary and scheduling
          needs.
        </aside>
        <h3>Secret Shopper</h3>
        <p>
          Agents with a minimum of 10 years experience play at your table games
          and identify security holes, equipment and procedure vulnerabilities,
          weak dealers and supervisors, and potential problems that can
          negatively affect the profitability of table games.
        </p>
        <h3>Guest Speaker</h3>
        <p>
          Sal Piacente, president of UniverSal Game Protection Development, Inc.
          speaks on various aspects of table games and surveillance operations
          or personal memory enhancement. Some venues in which he was a guest
          speaker include:
        </p>
        <ul>
          <li>G2E Asia - Macau*</li>
          <li>G2E - Las Vegas*</li>
          <li>World Game Protection Conference - Las Vegas</li>
          <li>Casino Risk Forum - Australia</li>
          <li>Iowa Gaming Association</li>
          <li>Oregon Surveillance Network</li>
          <li>Canadian Association of Casino Security Directors</li>
          <li>Canadian Gaming Summit &amp; Exhibition</li>
          <li>Riverboat Gaming Expo</li>
          <li>N.I.G.A.</li>
        </ul>

        <h3>Memory Training</h3>
        <p>
          Sal Piacente can teach you and your staff methods to improve your
          retention skills. Remember names, lists, procedures, and number
          sequences (like important phone or account numbers), with little
          effort
        </p>
        <h3>Video Play Review</h3>
        <p>
          Are you suspicious about a certain player(s) win, whether or not it
          was legitimate? Contact us and we will review your footage and check
          for foul play.
        </p>

        <h3>Reference Material</h3>
        <p>Free printouts for training sessions and study aids</p>
      </div>
      <div className={imagesContainer}>
        <div className={image}>
          <img
            src={wgpImage}
            alt="Sal speaking at the World Game Protection Conference"
          />
          <em>Sal at the 2011 World Game Protection Conference</em>
        </div>

        <div className={image}>
          <img
            src={g2EVegas}
            alt="Sal demonstrating techniques at G2E Las Vegas"
          />
          <em>
            Sal bewilders spectators at G2E in Las Vegas with his card memory
            stunts
          </em>
        </div>

        <div className={image}>
          <img
            src={casinoRiskForum}
            alt="Casino Risk Forum in Melbourne Australia"
          />
          <em>Sal at Casino Risk Forum, Melbourne, Australia</em>
        </div>
      </div>
    </div>
  )
}

export default Services
