import React from "react"
import Layout from "../components/layout"
import { Seo } from "../components/seo"
import Services from "../components/services"

const ServicesPage = () => (
  <Layout>
    <Services />
  </Layout>
)

export default ServicesPage

export const Head = () => <Seo title="Services | Universal Game Protection" />
